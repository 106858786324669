@use '../../../assets/styles//mixins' as mixin;

.about {

    .header {
        h2 {
            padding-top: 30px;

            @include mixin.xs {
                padding-top: 10px;
                text-align: center;
            }
        }
    }

    .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        @include mixin.xs {
            grid-template-columns: 1fr;
            row-gap: 10px;
        }

        .contentItem {
            display: grid;
            grid-template-columns: 25px 1fr;
            padding: 20px;
            max-height: 320px;

            margin-bottom: 15px;

            @include mixin.md-lt {
                height: 350px;
                padding: 0px;
            }

            @include mixin.xs {
                height: 250px;
                padding-left: 5px;
            }

            .icon {
                i {
                    height: 50px;
                    padding: 10px 0
                }
            }

            .textContent {
                padding: 0 10px;

                .textContainer {
                    min-height: 200px;

                    @include mixin.xs {
                        max-height: 200px;
                        padding: 0px;
                    }

                    h3 {
                        height: 50px;
                        padding: 10px 0;

                        @include mixin.md-lt {
                            height: 80px;
                        }

                        @include mixin.xs {
                            height: 40px;
                        }
                    }

                    p {
                        @include mixin.md-lt {
                            min-height: 200px;
                        }

                        @include mixin.xs {
                            height: 100px;
                        }
                    }
                }

            }

        }
    }

}