@use '../../../assets/styles//mixins' as mixin;

.adSlider {
    background: white;
    box-shadow: var(--box-shadow);
    height: 150px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 960px;

    @include mixin.md-lt {
        width: 750px;
    }

    @include mixin.sm-lt {
        width: 567px;
    }

    @include mixin.sm-lt {
        width: 300px;
    }

    @mixin white-gradient {
        background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }

    $animationSpeed: 40s;

    // Animation
    @keyframes scroll {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(calc(-250px * 7))
        }
    }

    &:before,
    &:after {
        @include white-gradient;
        content: "";
        height: 150px;
        position: absolute;
        width: 300px;
        z-index: 2;

        @include mixin.sm-lt {
            width: 100px;
        }
    }

    &:after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &:before {
        left: 0;
        top: 0;
    }

    .adSliderTrack {
        animation: scroll $animationSpeed linear infinite;
        display: flex;
        width: calc(250px * 14);
    }

    .slideImg {
        height: 120px;
        width: 200px;
        margin: 30px;
        // box-shadow: var(--box-shadow);
    }

}