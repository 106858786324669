@use './mixins' as mixin;

.container {
    max-width: 1200px;
    margin: auto;
    width: 100%;
}

.card {
    border-radius: 20px;
    background-color: white;
}

.cardItem {
    box-shadow: var(--box-shadow);

    .cardImg {
        height: 200px;
    }

    .cardText {
        padding-bottom: 10px;
        min-height: 280px;

        @include mixin.lg-lt {
            min-height: 310px;
        }

        h3 {
            text-align: center;
        }

        p {
            text-align: center;
        }
    }
}

.rounded_img {
    border-radius: 100%;
}

.calc {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
}

.calc-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

// TEXT

.text-align-center {
    text-align: center;
}

.uppercase {
    text-transform: uppercase;
}

.text-lead {
    font-size: 17px;
    line-height: 30px;
    font-weight: 400;
}