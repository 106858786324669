.MenuContainer {
    position: absolute;
    top: 100px;
    right: 0px;
    width: 100%;
    background-color: var(--gray-2);
    z-index: 1;

    li {
        padding: 10px 15px;
        cursor: pointer;
        transition: background-color 0.2s, color 0.2s;

        &:hover {
            background-color: var(--primary);
        }

        a {
            color: black;
        }
    }
}