@use '../../../assets/styles//mixins' as mixin;

.slider {
    text-align: left;
    padding-left: 20px;

    .slideItem {
        color: white;

        .content {
            font-style: italic;
            font-size: 20px;
            font-weight: 500;
            min-height: 100px;

        }

        .title {
            font-size: 20px;
            font-weight: 700;
        }
    }

    .icons {
        i {
            font-size: 20px;
            font-weight: 700;
        }
    }

    .radio {
        padding-bottom: 10px;
    }
}