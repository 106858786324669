:root {
    --primary: #b22222;
    --black-1: #000;
    --gray-1: #20232a;
    --gray-2: #efefef;
    --gray-3: #fefefe;
    --gray-4: #fff;
    --font-family: 'Roboto', sans-serif;
    // --font-family: 'Montserrat', sans-serif;
    --box-shadow: 0 6px 9px 0 rgb(0 0 0 / 15%);
    --box-shadow-nav: 0px 2px 18px 0px rgb(0 0 0 / 22%);
    --text-color: #444;
}