@use '../../../assets/styles//mixins' as mixin;

.testimonials {
    position: relative;
    background: url("../../../assets/images/info.jpg") center /cover;
    background-attachment: fixed !important;
    background-position: top !important;
    min-height: 400px;
    z-index: 1;

    @include mixin.md-lt {
        height: 500px;
    }

    .content {
        z-index: 2;

        h2 {
            padding: 0;
        }
    }
}