.info {
    position: relative;
    background: url("../../../assets/images/info.jpg") center /cover;
    background-attachment: fixed !important;
    background-position: top !important;
    min-height: 400px;
    z-index: 1;

    .infoContent {
        z-index: 2;
        padding: 0 20px;
    }
}