@use '../../assets/styles//mixins' as mixin;

.footer {
    background-color: var(--black-1);
    color: white;

    @include mixin.sm-lt {
        flex-direction: column;
    }

    span {
        @include mixin.sm-lt {
            padding-bottom: 20px;
        }
    }

    i {
        font-size: 30px;

        &:last-child {
            font-size: 40px;
        }
    }
}