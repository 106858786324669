@use '../../assets/styles//mixins' as mixin;

.header {
    height: 100px;
    background-color: var(--gray-3);
    box-shadow: var(--box-shadow);
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 20px;
    width: 100%;
    z-index: 2;
    position: fixed;


    @include mixin.md-lt {
        grid-template-columns: 100px 1fr 50px;
    }


    .logo {
        img {
            padding: 5px 10px;
        }
    }

    .headerInfo {
        display: grid;
        grid-template-columns: 1fr 400px;

        @include mixin.lg {
            grid-template-columns: 1fr 360px;
        }

        @include mixin.md-lt {
            grid-template-columns: 1fr;
        }

        .headerList {
            a {
                text-decoration: none;
                color: var(--primary);
                transition: 0.2s color;
                font-weight: bold;

                &:hover {
                    color: var(--gray-1);
                }

                @include mixin.lg {
                    font-size: 15px;
                }
            }

            @include mixin.md-lt {
                display: none;
            }
        }

        .contact {
            @include mixin.md-lt {
                display: flex;
                flex-direction: row;
                place-content: center center;
            }

            @include mixin.sm-lt {
                display: flex;
                flex-direction: column;
                place-content: center center;
            }

            @include mixin.xs {
                display: none;
            }

            .item {
                display: grid;
                grid-template-columns: 30px 1fr;

                .itemIcon {
                    display: grid;
                    grid-template-columns: 20px 1fr;
                }

                @include mixin.md-lt {
                    display: flex;
                    flex-direction: row;
                    place-content: center center;
                    padding: 10px;
                }
            }
        }
    }


    .headerXs {
        display: none;
        cursor: pointer;
        transition: opacity 0.2s;

        &:hover {
            opacity: 0.8;
        }

        @include mixin.md-lt {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            justify-content: center;
            font-size: 50px;
            color: var(--primary);
        }
    }
}