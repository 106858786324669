body {
    margin: 0;
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text-color);
    background-color: var(--gray-3);
}

h1,
h1,
h3,
h4 {
    margin: 0;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

img {
    max-width: 100%;
}

input {
    font-family: var(--font-family);
    border: none;
}

::placeholder {
    font-family: var(--font-family);
}

section {
    padding-bottom: 30px;
}