@use '../../../assets/styles//mixins' as mixin;

.teams {
    position: relative;
    background: url("../../../assets/images/info.jpg") center /cover;
    background-attachment: fixed !important;
    background-position: top !important;
    min-height: 400px;
    z-index: 1;

    h2 {
        padding-top: 30px;
        padding-bottom: 30px;
        z-index: 2;
    }

    .content {
        z-index: 2;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        padding: 20px;

        @include mixin.sm-lt {
            grid-template-columns: 1fr;
        }

        h3 {
            padding-top: 30px;
            padding-bottom: 3px;
        }

    }
}