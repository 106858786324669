.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-fill {
    flex: 1 1 auto;
}

.flex-jcc {
    justify-content: center;
}

.flex-aic {
    align-items: center;
}

.flex-pcc {
    justify-content: center;
    align-items: center;
}

.d-flex-row-pcc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.d-flex-row-jcc {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.d-flex-row-aic {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.d-flex-column-pcc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.d-flex-column-jcc {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.d-flex-column-aic {
    display: flex;
    flex-direction: column;
    align-items: center;
}