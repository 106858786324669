@use '../../../assets/styles//mixins' as mixin;

.banner {
    position: relative;
    min-height: calc(100vh - 210px);
    background: url("../../../assets/images/consulting-conseil.png") center /cover;
    background-attachment: fixed !important;
    background-position: top !important;
    z-index: 1;

    .bannerText {
        z-index: 2;

        h1 {
            font-size: 48px;
            font-weight: 900;
            letter-spacing: 1px;
            text-transform: none;
            padding-bottom: 30px;

            padding-top: 150px;
            padding-left: 10px;

            @include mixin.xs {
                font-size: 38px;
            }
        }

        p {
            font-weight: 400;
            font-size: 17px;
            line-height: 29px;
            max-width: 650px;
            margin: 0 auto 12px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    background: rgba(8, 8, 8, .897);
    color: white;
    height: 100px;
    padding-left: 30px;
    padding-bottom: 0;

    h2 {
        font-size: 17px;
    }

}