// btn
.btn {
    padding: 10px 15px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    transition: 0.2s opacity;

    &:hover {
        opacity: 0.8;
    }
}

.btn-primary {
    border: 2px solid var(--primary);
    color: white;
    background-color: var(--primary);
}

.btn-reverse-primary {
    border: 2px solid var(--primary);
    color: var(--primary);
    background-color: white;
}

// Text
.text-primary {
    color: var(--primary);
}

.text-gray-1 {
    color: var(--gray-1);
}

.text-gray-3 {
    color: var(--gray-3);
}

.text-black-1 {
    color: var(--black-1);
}

.text-white-1 {
    color: white;
}


//ICON

.icon-primary {
    color: var(--primary);
}