@use '../../../assets/styles//mixins' as mixin;

.articles {
    h2 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
        padding: 20px;

        @include mixin.lg-lt {
            grid-template-columns: 1fr 1fr;
        }

        @include mixin.sm-lt {
            grid-template-columns: 1fr;
        }

        h3 {
            padding-top: 30px;
            padding-bottom: 3px;
        }
    }
}