@use '../../../assets/styles//mixins' as mixin;

.galeries {
    z-index: 2;

    .content {
        h2 {
            padding: 20px;
        }

        .img {
            .addedClass {
                width: 800px;
                height: 500px;
                overflow: hidden;

                @include mixin.md-lt {
                    width: 500px;
                    height: 500px;
                }

                @include mixin.sm-lt {
                    width: 400px;
                }

                @include mixin.sm-lt {
                    width: 350px;
                    height: 300px;
                }
            }


        }
    }
}