//PADDING
.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}

.p-30 {
    padding: 30px;
}

.p-40 {
    padding: 30px;
}

.p-50 {
    padding: 30px;
}


.pl-20 {
    padding-left: 20px;
}

.pb-20 {
    padding-bottom: 20px;
}



.pl-30 {
    padding-left: 30px;
}

.pb-30 {
    padding-bottom: 30px;
}

.px-20 {
    padding-left: 30px;
    padding-right: 30px;
}

.px-30 {
    padding-left: 30px;
    padding-right: 30px;
}

// MARGIN
.m-5 {
    margin: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.m-10 {
    margin: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.m-20 {
    margin: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 20px;
}

.mr-30 {
    margin-right: 30px;
}

.mt-30 {
    margin-top: 30px;
}

.my-30 {
    margin-bottom: 30px;
    margin-top: 30px;
}

.mt-100 {
    margin-top: 100px;
}