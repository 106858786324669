@use '../../../assets/styles//mixins' as mixin;

.contact {
    position: relative;
    background: url("../../../assets/images/info.jpg") center /cover;
    background-attachment: fixed !important;
    background-position: top !important;
    min-height: 400px;
    z-index: 1;

    .content {
        z-index: 2;

        h2 {
            padding: 0;
        }

        .item {
            display: grid;
            grid-template-columns: 500px 1fr;
            color: white;
            padding: 30px;

            @include mixin.md-lt {
                grid-template-columns: 1fr;
            }

            .address {
                margin: 20px;
            }

            .form {
                .formItem {
                    display: grid;
                    grid-template-columns: 1fr;
                    padding: 5px;

                    &:first-child {
                        grid-template-columns: 1fr 1fr;
                    }

                    input {
                        border-radius: 7px;
                        height: 40px;
                    }
                }

                .formBtn {
                    margin-top: 20px;
                }
            }
        }
    }
}